import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './LoaderHorizontal.css';

const LoaderHorizontal = ({ className, animate }) => {
  return <span className={clsx('loader-horizontal', className, { 'loader-horizontal_animate': animate })}></span>;
};

LoaderHorizontal.propTypes = {
  className: PropTypes.string,
  animate: PropTypes.bool,
};

export default LoaderHorizontal;
