import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useSelector } from 'react-redux';
import { layers } from './layers'
import { LayersUtils } from 'utils/layers.utils';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MAP_ID = 'map-container'

const MapboxMap = ({onMapClick, onMapLoad, onBaseLayerChange}) => {
  const mapState = useRef()
  const baseLayer = useRef()

  const { mapType, terrain3D, attributes } = useSelector(
    (state) => state.map
  );

  const mapInitialization = ({
    onMapClick,
    onMapLoad
  }) => {

    mapboxgl.accessToken = 'pk.eyJ1Ijoic2NobWl0dGZ1bWlhbiIsImEiOiJjbHp0aWFrNngyOW54MnJxcm9jcWlvbXU4In0.PNc1P4k2SiTlLLa_q6XiZg';

    const map = new mapboxgl.Map({
      container: MAP_ID,
      zoom: 1,
      style: {
        glyphs: "https://api.maptiler.com/fonts/{fontstack}/{range}.pbf?key=QAhRFnpecjdzPphpL8hz",
        version: 8,
        sources: {},
        layers: [],
        sprite: "https://api.maptiler.com/maps/streets-v2/sprite"
      },
      center: [30, 15]
    });

    map.on('click', (event) => {
      if (onMapClick) onMapClick({...event, map})
    })

    map.on('load', (event) => {
      mapState.current = map
      changeBaseLayer()
      if (onMapLoad) onMapLoad(event)
    })
  
    return map
  }

  const createLayer = async({ id, tiles, type, url, mapType: googleMapType, layerTypes }) => {
    const map = mapState.current

    if (type === 'vector') {
      await LayersUtils.addVectorLayer({
        map,
        url,
        source: id
      })

      return
    }

    if (type === 'google') {
      await LayersUtils.toggleGoogleLayer({
        map,
        mapType: googleMapType,
        layerTypes,
        source: id
      })
    }

    if (map.getSource(id)) return

    await map.addSource(id, {
      type,
      tiles
    })

    await map.addLayer({
      id: `${id}-${type}`,
      source: id,
      type
    })
  }

  const toggleActiveLayer = (visibility) => {
    const map = mapState.current

    const mapActiveLayers = map.getStyle().layers

    mapActiveLayers.forEach(({ source, id }) => {
      if (source === baseLayer.current) {

        map.setLayoutProperty(id, 'visibility', visibility) // Display current layer

        if (visibility === 'visible') map.moveLayer(id)
      }
    })
  }

  const changeBaseLayer = async () => {
    const layer = layers[mapType]
    
    try {
      if (!layer) {
        console.error(`Layer ${mapType} is not exist`);
        return
      }
  
      const map = mapState.current
  
      if (!map) return;
  
      if (baseLayer.current) toggleActiveLayer('none')
  
      const { id } = layer
  
      const isLayerExisted = map.getSource(id)
  
      baseLayer.current = id
  
      if (!isLayerExisted) {
        await createLayer(layer)
      } else {
        toggleActiveLayer('visible')
      }
      
    } catch (error) {
      console.error(error);
      
    } finally {
      if (onBaseLayerChange) await onBaseLayerChange(layer)
    }
  }

  const toggleOverlayLayer = (layer) => {
    const map = mapState.current

    map.addSource(layer, {
      type: 'vector'
    })
  }

  const toggleOverlays = () => {
    const activeLayers = Object.entries(attributes)

    activeLayers.forEach(([layer, visibility]) => {

    })
  }

  useEffect(() => {
    LayersUtils.toggle3DTerrain({map: mapState.current, visible: terrain3D})
  }, [terrain3D])

  useEffect(() => {
    changeBaseLayer()
  }, [mapType])

  useEffect(() => {
    mapInitialization({onMapClick, onMapLoad})
  }, [ ])

  useEffect(() => {
    toggleOverlays()
  }, [attributes])

  return (
    <div className='flight-history-map' style={{width: '100%', height: '100vh'}} id={MAP_ID}></div>
  )
}

export default MapboxMap