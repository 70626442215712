/**
 * @enum {string}
 * Prefixes
 * - PAGE_ for opening pages
 * - OPTION_ for some options on the page
 */
export const permissions = {
  PAGE_DEFAULT: 'PAGE_DEFAULT',
  OPTION_DEFAULT: 'OPTION_DEFAULT',
  OPTION_GROUP_NAME: 'OPTION_GROUP_NAME',
  OPTION_LOCAL_BROWSER_TIME: 'OPTION_LOCAL_BROWSER_TIME',
  OPTION_SENSOR_SID: 'OPTION_SENSOR_SID',
  OPTION_SENSOR_AWAITING_ICON: 'OPTION_SENSOR_AWAITING_ICON',
  OPTION_ADDRESS_LOOKUP: 'OPTION_ADDRESS_LOOKUP',
  OPTION_EXTENDED_AIRCRAFT_INFO: 'OPTION_EXTENDED_AIRCRAFT_INFO',
  OPTION_OFFLINE_AIRCRAFT_MOVE: 'OPTION_OFFLINE_AIRCRAFT_MOVE',
  OPTION_OFFLINE_AIRCRAFT_DELETE: 'OPTION_OFFLINE_AIRCRAFT_DELETE',
  OPTION_OFFLINE_AIRCRAFT_LAYER: 'OPTION_OFFLINE_AIRCRAFT_LAYER',
  OPTION_GEOFENCE_LAYER: 'OPTION_GEOFENCE_LAYER',
  OPTION_ORIGIN_FILTER: 'OPTION_ORIGIN_FILTER',
  /** flights modal */
  OPTION_FLIGHTS: 'OPTION_FLIGHTS',
  /** isDebug flag */
  OPTION_DEBUG: 'OPTION_DEBUG',
  OPTION_ATC_FEED: 'OPTION_ATC_FEED',

  PAGE_SCHEDULED_FLIGHTS: 'PAGE_SCHEDULED_FLIGHTS',
  OPTION_SCHEDULED_FLIGHTS: 'OPTION_SCHEDULED_FLIGHTS',
  PAGE_NOISE_ABATEMENT: 'PAGE_NOISE_ABATEMENT',
  OPTION_NOISE_ABATEMENT: 'OPTION_NOISE_ABATEMENT',

  PAGE_AIRPORT_DEFAULT: 'PAGE_AIRPORT_DEFAULT',
  OPTION_AIRPORT_DEFAULT: 'OPTION_AIRPORT_DEFAULT',
  OPTION_AIRPORT_SETTINGS: 'OPTION_AIRPORT_SETTINGS',
  OPTION_BASED_AIRCRAFT_DELETE: 'OPTION_BASED_AIRCRAFT_DELETE',
  OPTION_OPERATIONS_DELETE: 'OPTION_OPERATIONS_DELETE',
  PAGE_AIRPORT_SETTINGS: 'PAGE_AIRPORT_SETTINGS',
  PAGE_AIRPORT_TV_SCREENS: 'PAGE_AIRPORT_TV_SCREENS',
  PAGE_FIDS_MANAGEMENT: 'PAGE_FIDS_MANAGEMENT',
  OPTION_FIDS_MANAGEMENT_FULL_LIST: 'OPTION_FIDS_MANAGEMENT_FULL_LIST',

  PAGE_AIRPORT_LOGINS: 'PAGE_AIRPORT_LOGINS',
  PAGE_GROUP_REPORT: 'PAGE_GROUP_REPORT',

  PAGE_SYSTEM_MANAGEMENT: 'PAGE_SYSTEM_MANAGEMENT',
  PAGE_SUPPORT: 'PAGE_SUPPORT',
};

/**
 * @enum {string}
 */
export const permissionLevels = {
  USER: 'USER',
  SCREEN_USER: 'SCREEN_USER',
  AIRPORT_USER: 'AIRPORT_USER',
  AIRPORT_ADMIN: 'AIRPORT_ADMIN',
  GROUP_VIEWER: 'GROUP_VIEWER',
  SUPERUSER: 'SUPERUSER',
};

export const permissionLevelsLabels = {
  USER: 'User',
  SCREEN_USER: 'Screens',
  AIRPORT_USER: 'Airport User',
  AIRPORT_ADMIN: 'Airport Admin',
  GROUP_VIEWER: 'Group Viewer',
  SUPERUSER: 'Superuser',
};

const permissionLevelToPermissionsList = {
  // default role
  [permissionLevels.USER]: [permissions.PAGE_DEFAULT, permissions.PAGE_SUPPORT, permissions.OPTION_DEFAULT],
};

/** SCREEN_USER role used for screens usually opened 24/7 */
permissionLevelToPermissionsList[permissionLevels.SCREEN_USER] = [
  ...permissionLevelToPermissionsList[permissionLevels.USER],
  permissions.PAGE_AIRPORT_TV_SCREENS,
];
/** AIRPORT_USER */
permissionLevelToPermissionsList[permissionLevels.AIRPORT_USER] = [
  ...permissionLevelToPermissionsList[permissionLevels.SCREEN_USER],
  permissions.PAGE_SCHEDULED_FLIGHTS,
  permissions.PAGE_NOISE_ABATEMENT,
  permissions.PAGE_AIRPORT_DEFAULT,

  permissions.OPTION_AIRPORT_DEFAULT,
  permissions.OPTION_OFFLINE_AIRCRAFT_LAYER,
];
/** AIRPORT_ADMIN */
permissionLevelToPermissionsList[permissionLevels.AIRPORT_ADMIN] = [
  ...permissionLevelToPermissionsList[permissionLevels.AIRPORT_USER],
  permissions.PAGE_AIRPORT_SETTINGS,
  permissions.PAGE_FIDS_MANAGEMENT,

  permissions.OPTION_GEOFENCE_LAYER,
  permissions.OPTION_AIRPORT_SETTINGS,
  permissions.OPTION_BASED_AIRCRAFT_DELETE,
  permissions.OPTION_OPERATIONS_DELETE,
  permissions.OPTION_OFFLINE_AIRCRAFT_MOVE,
];
/** GROUP_VIEWER */
permissionLevelToPermissionsList[permissionLevels.GROUP_VIEWER] = [
  permissions.PAGE_DEFAULT,
  permissions.PAGE_AIRPORT_LOGINS,
  permissions.PAGE_GROUP_REPORT,
  permissions.PAGE_SUPPORT,

  permissions.OPTION_DEFAULT,
  permissions.OPTION_GROUP_NAME,
  permissions.OPTION_LOCAL_BROWSER_TIME,
];
/** SUPERUSER */
permissionLevelToPermissionsList[permissionLevels.SUPERUSER] = [
  permissions.PAGE_DEFAULT,
  permissions.PAGE_NOISE_ABATEMENT,
  permissions.PAGE_AIRPORT_LOGINS,
  permissions.PAGE_GROUP_REPORT,
  permissions.PAGE_SYSTEM_MANAGEMENT,
  permissions.PAGE_FIDS_MANAGEMENT,

  permissions.OPTION_DEFAULT,
  permissions.OPTION_SENSOR_SID,
  permissions.OPTION_GEOFENCE_LAYER,
  permissions.OPTION_OFFLINE_AIRCRAFT_LAYER,
  permissions.OPTION_OFFLINE_AIRCRAFT_MOVE,
  permissions.OPTION_OFFLINE_AIRCRAFT_DELETE,

  permissions.OPTION_EXTENDED_AIRCRAFT_INFO,
  permissions.OPTION_ADDRESS_LOOKUP,
  permissions.OPTION_ORIGIN_FILTER,
  permissions.OPTION_NOISE_ABATEMENT,
  permissions.OPTION_ATC_FEED,
  permissions.OPTION_FIDS_MANAGEMENT_FULL_LIST,
];

/**
 * Retrieves the list of permissions for a user based on their permission level and feature flags.
 *
 * @param {string} permissionLevel - The permission level of the user (e.g., 'user', 'admin', 'superadmin').
 * @param {Object} flags - An object containing feature flags.
 * @param {boolean} [flags.hasFeatureNoiseAbatement] - Indicates if the noise abatement feature is enabled.
 * @param {boolean} [flags.hasFeatureScheduledFlights] - Indicates if the scheduled flights feature is enabled.
 * @param {boolean} [flags.debugMode] - Indicates if the debug mode is enabled.
 * @returns {Array} - A list of permissions for the user.
 *
 * @example
 *
 * const userPermissions = getUserPermissionsList(permissionLevels.admin, {
 *   hasFeatureNoiseAbatement: true,
 *   hasFeatureScheduledFlights: false,
 *   debugMode: true,
 * });
 * console.log(userPermissions);
 * // Output: ['PAGE_DEFAULT', 'OPTION_DEFAULT', 'PAGE_NOISE_ABATEMENT']
 */
export function getUserPermissionsList(permissionLevel, flags) {
  const hasFeatureNoiseAbatement = flags?.hasFeatureNoiseAbatement;
  const hasFeatureScheduledFlights = flags?.hasFeatureScheduledFlights;

  const rolePermissionsList = permissionLevelToPermissionsList[permissionLevel];

  if (hasFeatureNoiseAbatement) rolePermissionsList.push(permissions.OPTION_NOISE_ABATEMENT);
  if (hasFeatureScheduledFlights) rolePermissionsList.push(permissions.OPTION_SCHEDULED_FLIGHTS);
  if (flags?.debugMode && permissionLevel === permissionLevels.SUPERUSER)
    rolePermissionsList.push(permissions.OPTION_DEBUG);
  return rolePermissionsList || [];
}
