import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SelectSearch from 'react-select-search';

import './CustomSelect.css';

/**
 * @typedef Option - Select options format
 * @param {string} name - Displayed name
 * @param {string} value - Saved value
 */

/**
 * @param {Option[]} options - Custom select options
 */
const CustomSelect = ({ className, error, options, wrapperStyle, condensed, loading, ...props }) => {
  return (
    <div className={clsx('custom-select__wrap', className)} style={wrapperStyle}>
      <SelectSearch
        className={clsx('custom-select', {
          'custom-select_condensed': condensed,
          'custom-select_loading': loading,
        })}
        options={options}
        {...props}
      />
      {props.clearable && props.value && <div className='custom-select__close' onClick={props.onClear}>&times;</div>}
      {error && <span className="custom-select__error">{error}</span>}
    </div>
  );
};

CustomSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onClear: PropTypes.func,
  error: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  wrapperStyle: PropTypes.shape({
    width: PropTypes.number,
    maxWidth: PropTypes.number,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  condensed: PropTypes.bool,
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default CustomSelect;
