import { Button } from 'components/UI/Button';
import React, { useEffect, useRef, useState } from 'react';
import { DatePicker } from 'rsuite';
import AccordionTab from 'components/base/AccordionTab';


const generateCurrentDate = () => {
  const currentDate = new Date();
  const pastHour = new Date(currentDate.getTime() - 60 * 60 * 1000); 

  return {
    from: pastHour.toISOString(), 
    to: currentDate.toISOString() 
  };
}

const defaultValue = {
  radius: 30,
  ...generateCurrentDate()
}

const FlightHistoryData = ({ onChange }) => {
   const [isTabOpened, setTabOpened] = useState(true)
  
  const dateRangePickerRef = useRef();

  const [historyData, setHistoryData] = useState(defaultValue)

  const handleHistoryDataChange = (data) => setHistoryData({...historyData, ...data}) 

  const onTabToggle = (value) => setTabOpened(value)

  const handleDateSelect = (date, field) => {
    if (!date) {
      handleHistoryDataChange(generateCurrentDate())
      return
    }

    handleHistoryDataChange({
      [field]: date.toISOString(),
    })
  }

  const resetData = () => {
    const datePickerClearButton = dateRangePickerRef.current.root.querySelector('.rs-picker-clean')

    if (datePickerClearButton) datePickerClearButton.click()

    setHistoryData(defaultValue)
  }

  useEffect(() => onChange(historyData), [historyData])

  return (
    <div className='flight-history-tab'>
      <AccordionTab label='Time' name='date' opened={isTabOpened} setOpened={onTabToggle}>
        {/* <div className='flight-history-block__header'>
          <Button text='Reset filters' type='outline' size='sm' rounded onClick={resetData} />
        </div> */}
        <div className='flight-history-data'>
          <div className='flight-history-data__label'>
            Time (from) 
          </div>
          <div>
            <DatePicker ref={dateRangePickerRef} character=" – " defaultValue={new Date()} size='sm' className='flight-history-datepicker' placeholder="Select flight date range" format="MM/dd/yyyy hh:mm aa" onChange={(range) => handleDateSelect(range, 'from')} />
          </div>
        </div>
        <div className='flight-history-data'>
          <div className='flight-history-data__label'>
            Time (to) 
          </div>
          <div>
            <DatePicker ref={dateRangePickerRef} character=" – " defaultValue={new Date()} size='sm' className='flight-history-datepicker' placeholder="Select flight date range" format="MM/dd/yyyy hh:mm aa" onChange={(range) => handleDateSelect(range, 'to')} />
          </div>
        </div>
      </AccordionTab>
    </div>
  )
}

export { FlightHistoryData }