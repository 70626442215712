import AccordionTab from 'components/base/AccordionTab';
import Button from 'components/base/Button';
import CustomSelect from 'components/base/CustomSelect';
import { ALTITUDE_TYPE_GNSS_CORRECTED, ALTITUDE_TYPE_GNSS_RAW, ALTITUDE_TYPE_QFE_CORRECTED, ALTITUDE_TYPE_QNH_CORRECTED, ALTITUDE_TYPE_QNHS_CORRECTED, ALTITUDE_TYPE_RAW } from 'pages/NoiseAbatement/noiseAbatementHelpers';
import React, { useState } from 'react';
import { GeometryUtils } from 'utils/geometry.utils';

const exportTypes = [
  {
    name: 'KML',
    value: 'kml'
  },
  {
    name: 'KMZ',
    value: 'kmz'
  }
]

const altitudeTypeOptions = [
  { name: 'Raw', value: ALTITUDE_TYPE_RAW },
  { name: 'GNSS Raw', value: ALTITUDE_TYPE_GNSS_RAW },
  { name: 'GNSS Geoid Corrected', value: ALTITUDE_TYPE_GNSS_CORRECTED },
  { name: 'QNH Corrected', value: ALTITUDE_TYPE_QNH_CORRECTED },
  { name: 'QFE Corrected', value: ALTITUDE_TYPE_QFE_CORRECTED },
  { name: 'QNHS Corrected', value: ALTITUDE_TYPE_QNHS_CORRECTED },
];

const FlightHistoryExport = ({map}) => {

  const [dataType, setDataType] = useState('kml')
  const [altitudeType, setAltitudeType] = useState('clampToGround')
  const [isTabOpened, setTabOpened] = useState(false)

  const onTabToggle = (value) => setTabOpened(value)

  const exportData = () => {
    const data = map.getSource('history-source')._data

    if (dataType === 'kml') {
      GeometryUtils.downloadKML(data, altitudeType)
    } else if (dataType === 'kmz') {
      GeometryUtils.downloadKMZ(data, altitudeType)
    }
  }

  const onDataTypeChange = (data) => {
    setDataType(data)
  }

  const onAltitudeTypeChange = (data) => {
    setAltitudeType(data)
  }

  return (<div className='flight-history-tab flight-history-block--export'>
    <AccordionTab label='Export' name='test' opened={isTabOpened} setOpened={onTabToggle}>
      <div className='flight-history-data'>
        <div className='flight-history-data__label'>
          Altitude Type
        </div>
        <CustomSelect options={altitudeTypeOptions} className="flight-history-mb-5" onChange={onAltitudeTypeChange} />
      </div>

      <div className='flight-history-data'>
        <div className='flight-history-data__label'>
          File Type
        </div>
        <CustomSelect className='flight-history-mb-15' options={exportTypes} onChange={onDataTypeChange} />
      </div>
      <Button
        mods={{action: true}}
        className="noise_button"
        type="submit"
        onClick={exportData}
      >
        Export
       </Button>
    </AccordionTab>
  </div>)
}

export { FlightHistoryExport }