import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Page from 'components/common/Page';
import ScreensForm from './ScreensForm';
const Screens = lazy(() => import('./Screens'));

const ScreensPage = () => {
  const history = useHistory();
  const { screenId } = useParams();
  const { submitting } = useSelector((state) => state.screens);

  const isNewScreen = screenId === 'new';
  const screen = useSelector((state) => {
    return state.screens.screens.find((c) => c.id === screenId);
  });

  const isSidebarPanelShown = Boolean(screen) || isNewScreen;

  return (
    <Page
      renderSidebarPanel={isSidebarPanelShown ? () => <ScreensForm screen={screen} isNew={isNewScreen} /> : null}
      sidebarPanelLoading={submitting}
      sidebarPanelTitle={`${isNewScreen ? 'Create' : 'Edit'} FIDS`}
      onSidebarPanelClose={() => history.push(`/fids`)}
    >
      <Screens />
    </Page>
  );
};

export default ScreensPage;
