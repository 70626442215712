export const ScreenType = {
  IFRAME: 'iframe',
  SCREEN: 'screen',
};

export const ScreenTypeToString = {
  [ScreenType.IFRAME]: 'iFrame',
  [ScreenType.SCREEN]: 'Screen',
};

export const fidsTypeOptions = [
  { name: 'iFrame', value: ScreenType.IFRAME },
  { name: 'Screen', value: ScreenType.SCREEN },
];

export const DataSourceType = {
  DEPARTURES: 1,
  ARRIVALS: 2,
};

export const DataSourceTypeToString = {
  [DataSourceType.DEPARTURES]: 'Departures',
  [DataSourceType.ARRIVALS]: 'Arrivals',
};

export const dataSourceOptions = [
  { name: DataSourceTypeToString[DataSourceType.DEPARTURES], value: DataSourceType.DEPARTURES },
  { name: DataSourceTypeToString[DataSourceType.ARRIVALS], value: DataSourceType.ARRIVALS },
];
