/** SCREENS alias is FIDS */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

const initialState = {
  screens: [],
  fetching: true,
  submitting: false,
  dataFetched: false,
};

export const fetchScreens = createAsyncThunk('screens/fetchAll', async () => {
  return api.getScreens();
});

export const createScreen = createAsyncThunk('screens/create', async (screen) => {
  return api.createScreen(screen);
});

export const updateScreen = createAsyncThunk('screens/update', async (screen) => {
  return api.updateScreen(screen);
});

export const deleteScreen = createAsyncThunk('screens/delete', async (id) => {
  api.deleteScreen(id).catch(() => {
    /** Reload if there is issue with optimistic delete */
    window.location.reload();
  });
  return id;
});

export const screensSlice = createSlice({
  name: 'screens',
  initialState,
  extraReducers: {
    [fetchScreens.pending]: (state) => {
      state.fetching = true;
    },
    [createScreen.pending]: (state) => {
      state.submitting = true;
    },
    [updateScreen.pending]: (state) => {
      state.submitting = true;
    },

    [fetchScreens.fulfilled]: (state, action) => {
      state.screens = action.payload;
      state.fetching = false;
    },
    [createScreen.fulfilled]: (state, action) => {
      state.screens.push(action.payload);
      state.submitting = false;
    },
    [updateScreen.fulfilled]: (state, action) => {
      const screen = state.screens.find((u) => u.id === action.payload.id);
      if (screen) {
        Object.assign(screen, action.payload);
      }
      state.submitting = false;
    },
    [deleteScreen.fulfilled]: (state, action) => {
      const deletedId = action.payload;
      state.screens = state.screens.filter((u) => {
        return u.id !== deletedId;
      });
    },

    [fetchScreens.rejected]: (state) => {
      state.fetching = false;
    },
    [createScreen.rejected]: (state) => {
      state.submitting = false;
    },
    [updateScreen.rejected]: (state) => {
      state.submitting = false;
    },
  },
  reducers: {
    setDataFetched(state) {
      state.dataFetched = true;
    },
  },
});

export default screensSlice.reducer;
export const { setDataFetched } = screensSlice.actions;
