import React, { useEffect, useState } from 'react';
import './FlightHistory.css'
import 'rsuite/DatePicker/styles/index.css';
import MenuButton from '../SideNavigation/components/MenuButton';
import { FlightHistoryData } from './FlightHistoryData';
import { FlightHistoryFilter } from './FlightHistoryFilter';
import { FlightHistoryAddress } from './FlightHistoryAddress';
import { FlightHistoryExport } from './FlightHistoryExport';
import api from 'api/api';
import * as turf from '@turf/turf'
import { useHistory } from 'react-router-dom';
import { LayersUtils } from 'utils/layers.utils';
import { useSelector } from 'react-redux';

const targetProgress = 80; 

const FlightHistorySettings = ({ centerPoint, setLocationFromSearch, onHistoryResponse, onCenterTypeChange, onDataChange, centerType, map }) => {
  const history = useHistory();

  const {airport} = useSelector((state) => state.auth);
  
  const [data, setData] = useState({
    radius: 30,
    center: [0,0],
    altitude: 1000,
    upperCeiling: 1000,
    lowerCeiling: 5000
  })

  const [activities, setActivities] = useState([])

  const [progress, setProgress] = useState(0); 
  const [isLoading, setLoading] = useState(false)

  const handleDataChange = (payload) => {
    setData({ ...data, ...payload })
  }

  const onCenterChange = (center) => {

    if (!center) {
      LayersUtils.addRadiusPoint(map, {
        center: 'clear',
        color: '#24c1ff',
        radius: data.radius,
        sourceName: 'marker-radius',
      })

      window.positionMarker.remove()

      return
    }
    
    const [lat, lng] = Object.values(center)

    setData({
      ...data,
      center: [lng, lat]
    })

    setLocationFromSearch([lng, lat])
  }

  const loadHistoryData = async () => {
    setLoading(true)

    const historyData = await api.getHistoryData({
      ...data,
      radius: data.radius * 1852,
      center: data.center
    })

   const infoArray = Object.entries(historyData).filter(([, points]) => points.length > 1).map(([aircraftname, points]) => {
    const pointsArray = points.map(({altitude, latitude, longitude}) => {
      return [longitude, latitude, altitude < 0 ? 0 : altitude]
    })

    return turf.lineString(pointsArray, {aircraftname}, {id: aircraftname})
   })

   const historyCollection = turf.featureCollection(infoArray)

    onHistoryResponse(historyCollection)
    setProgress(100)  
    setLoading(false)

    setTimeout(() => {
      setProgress(0)
    }, 2000);
  }

  const getActivitiesList = async () => {
    const data = await api.getActivitiesByAirport({
      airport: airport.id
    })

    const activitiesList = data.map(({id, name}) => ({ value: id, name }))

    setActivities(activitiesList)
  }

  const closeModal = () => history.push('/dashboard')

  useEffect(() => {
    if (!isLoading) return;
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev < targetProgress) {
          const increment = Math.random() * (5 - 0.1) + 0.1;
          return Math.min(prev + increment, targetProgress); 
        }
        clearInterval(interval); 
        return prev;
      });
    }, 1000);

    return () => clearInterval(interval); 
  }, [isLoading]);

  useEffect(() => {
    onDataChange(data)
  }, [data])

  useEffect(() => {
    handleDataChange({center: centerPoint})
  }, [centerPoint])

  useEffect(() => {
    getActivitiesList()
  }, [])

  return ( 
  <div className='flight-history'> 
      <div className='flight-history-header'>
        <div className='flight-history-header__title'>
          Noise Abatement
        </div>
        <MenuButton className='flight-history-header__close' isCloseButton onClick={closeModal} />
      </div>
      <div className='flight-history-loader'>
       <div className='flight-history-loader__bar' style={{
          width: `${progress}%`,
          opacity: progress ? 1 : 0
        }}></div>
      </div>
    <div className='flight-history-body'>
      <FlightHistoryAddress setLocationFromSearch={onCenterChange} />
      <FlightHistoryData onChange={handleDataChange} centerType={centerType} onCenterTypeChange={onCenterTypeChange} />
      <FlightHistoryFilter activities={activities} onChange={handleDataChange} onSubmit={loadHistoryData} />
      <FlightHistoryExport map={map} />
    </div>
  </div> 
  );
}

export { FlightHistorySettings };