import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getPrettyInfoTags, sourceNames } from 'utils/mapUtils';
import { getAirportDateFormatter, getAirportTimeFormatter } from 'utils/dateTimeHelpers';
import { ReactComponent as Altitude } from 'components/common/LeafletMap/components/AircraftOverlay/icons/tag_altitude.svg';
import { ReactComponent as Speed } from 'components/common/LeafletMap/components/AircraftOverlay/icons/tag_speed.svg';
import { ReactComponent as VSpeed } from 'components/common/LeafletMap/components/AircraftOverlay/icons/tag_vertical_speed.svg';

import './FlightTag.css';

const FlightTag = ({ speed, altitude, registration, vSpeed, callsign, squawk, type, uti, src, cla = 0, timestamp = null }) => {
  const { timezone } = useSelector((state) => state.auth);
  const { reg, spd, alt, vrt, fli, typ, squ } = getPrettyInfoTags({
    reg: registration,
    spd: speed,
    alt: altitude,
    vrt: vSpeed,
    fli: callsign,
    typ: type,
    squ: squawk,
  });

  const date = new Date(timestamp || uti * 1000);

  return (
    <div className="tag_wrapper" id="flight-tag">
      <div className="tag_top">
        <div className="tag_data-item">{reg}</div>
        <div className="tag_flex">
          <div className="tag_data-item">
            <Speed className="tag_icon tag_spd" />
            {spd}
          </div>
          <div className="tag_data-item">
            <Altitude className="tag_icon tag_alt" />
            {alt}
          </div>
          <div className="tag_data-item">
            <VSpeed className="tag_icon tag_vrt" />
            {vrt}
          </div>
        </div>
      </div>
      <div className="tag_bottom">
        <div className="tag_flex">
          <div className="tag_data-item">{fli}</div>
          <div className="tag_data-item">{typ}</div>
        </div>
        <div className="tag_data-item">{squ}</div>
        <div className="tag_data-item">{sourceNames[`${src}${cla}`] || `${src}${cla}`}</div>
        <div className="tag_data-item">
          {`${getAirportDateFormatter(timezone).format(date)}, ${getAirportTimeFormatter(timezone, true).format(date)}`}
        </div>
      </div>
    </div>
  );
};

FlightTag.propTypes = {
  speed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  altitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  registration: PropTypes.string,
  vSpeed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  callsign: PropTypes.string,
  squawk: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  src: PropTypes.string,
  cla: PropTypes.number,
  uti: PropTypes.number,
  timestamp: PropTypes.string,
};

export default FlightTag;
