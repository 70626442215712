/**
 * @typedef {Object} NavigationLink
 * @property {string} name - The name of the link
 * @property {string} path - Any valid URL path
 * @property {boolean} external - Indicates if link should be opened in new tab
 * @property {string} modalName - The modal name. It's state saved in state.modal.controls[modalName]
 * and used for opening modal above navigation
 */

/**
 * @typedef {Object} NavigationInfo
 * @property {string} sectionName - The name of the section in navigation sidebar
 * @property {NavigationLink[]} element - Array of the navigation links
 */

import { permissions } from 'utils/userPermissions';

export const navigationCommonList = [
  {
    sectionName: 'Dashboard',
    links: [
      {
        name: 'Map',
        path: '/dashboard',
      },
    ],
    permission: permissions.PAGE_DEFAULT,
  },
  {
    sectionName: 'Operations',
    links: [
      {
        name: 'Summary & Log',
        path: '/operations',
      },
    ],
    permission: permissions.PAGE_AIRPORT_DEFAULT,
  },

  {
    sectionName: 'Scheduled Flights',
    links: [
      {
        name: 'Departures & Arrivals',
        path: '/scheduled',
        external: true,
        permission: permissions.OPTION_SCHEDULED_FLIGHTS,
        showDisabled: true,
      },
    ],
    permission: permissions.PAGE_AIRPORT_DEFAULT,
  },

  {
    sectionName: 'Based Aircraft',
    permission: permissions.PAGE_AIRPORT_DEFAULT,
    links: [
      {
        name: 'Summary & List',
        path: '/based-aircrafts',
      },
    ],
  },
  {
    sectionName: 'Flight History',
    links: [
      {
        name: 'Noise Abatement',
        path: '/noise',
        modalName: 'noiseAbatement',
        permission: permissions.OPTION_NOISE_ABATEMENT,
        showDisabled: true,
      },
      {
        name: 'Noise Abatement New',
        path: '/flight-history',
        modalName: 'flightHistory',
        permission: permissions.OPTION_NOISE_ABATEMENT,
        showDisabled: true,
      },
    ],
    permission: permissions.PAGE_NOISE_ABATEMENT,
  },
  {
    sectionName: 'Weather Information',
    links: [
      {
        name: 'METAR & TAF',
        path: '/weather/metaf',
      },
    ],
    permission: permissions.PAGE_AIRPORT_DEFAULT,
  },
  {
    sectionName: 'Reports',
    links: [
      {
        name: 'Operations',
        path: '/reports/operations',
      },
      {
        name: '5010 Summary',
        path: '/reports/5010',
      },
      {
        name: 'Based Aircraft',
        path: '/reports/based',
      },
      {
        name: 'Statistics',
        path: '/reports/statistic',
      },
    ],
    permission: permissions.PAGE_AIRPORT_DEFAULT,
  },

  {
    sectionName: 'Settings',
    links: [
      {
        name: 'Services & Fees',
        path: '/user-settings/services',
      },
      {
        name: 'FIDS',
        path: '/fids',
      },
    ],
    permission: permissions.PAGE_AIRPORT_SETTINGS,
  },

  {
    sectionName: 'Support',
    links: [
      {
        name: 'Contact',
        path: '/contact',
      },
    ],
    permission: permissions.PAGE_SUPPORT,
  },
];

export const navigationSuperAdminList = [
  {
    sectionName: 'Manage System Settings',
    links: [
      {
        name: 'General System Settings',
        path: '/settings',
      },
      {
        name: 'Customer Management',
        path: '/customers',
        exact: false,
      },
      {
        name: 'User Management',
        path: '/users',
        exact: false,
      },
      {
        name: 'Aircraft Database',
        path: '/aircrafts',
        exact: false,
      },
      {
        name: 'Geofences',
        path: '/geofences',
        exact: false,
      },
      {
        name: 'Activity Builder',
        path: '/activities',
        exact: false,
      },
      {
        name: 'Sensor Management',
        path: '/sensor-management',
        pathname: 'https://monitoring.virtower.net/d/e7043630-0b28-4198-aa47-e731bc5725e5',
        external: true,
      },
      {
        name: 'Backend Status',
        path: '/status',
      },
      {
        name: 'Audit Log',
        path: '/audit-log',
      },
      {
        name: 'FIDS',
        path: '/fids',
      },
    ],
    permission: permissions.PAGE_SYSTEM_MANAGEMENT,
  },
  {
    sectionName: 'External Databases',
    links: [
      {
        name: 'Based Aircraft',
        path: '/external-db/based',
      },
      {
        name: 'LADD',
        path: '/external-db/ladd',
      },
      {
        name: 'FAA',
        path: '/external-db/faa',
        permission: permissions.OPTION_DEBUG,
      },
      {
        name: 'Sync',
        path: '/external-db/sync',
        permission: permissions.OPTION_DEBUG,
      },
    ],
    permission: permissions.PAGE_SYSTEM_MANAGEMENT,
  },
];

export const navigationGroupViewList = [
  {
    sectionName: 'Group Access',
    links: [
      {
        name: 'Airport Logins',
        path: '/airport-logins',
      },
    ],
    permission: permissions.PAGE_AIRPORT_LOGINS,
  },
  {
    sectionName: 'Export',
    links: [
      {
        name: 'Group Report',
        path: '/export/group-report',
      },
    ],
    permission: permissions.PAGE_GROUP_REPORT,
  },
];
