import React, { useRef, useState } from 'react';
import LocationSearchInput from '../LocationSearch';
import AccordionTab from 'components/base/AccordionTab';
import { Button } from 'components/UI/Button';

const FlightHistoryAddress = ({setLocationFromSearch}) => {
  const [isTabOpened, setTabOpened] = useState(true)
  const locationRef = useRef()

  const onTabToggle = (value) => setTabOpened(value)

  const clearData = () => {
    setLocationFromSearch(null)
    locationRef.current('')
  }

  const getLocationRef = (locationValueSetter) => {
    locationRef.current = locationValueSetter
  }

  return ( 
    <div className='flight-history-tab'>
    <AccordionTab label='Set Reference Marker' name='address' opened={isTabOpened} setOpened={onTabToggle}>
      <div className='flight-history-data'>
        <div className='flight-history-block__header'>
          <Button text='Delete' type='outline' size='sm' rounded onClick={clearData} />
        </div>
        <div className='flight-history-data__label'>
          Select Address
        </div>
        <LocationSearchInput innerRef={getLocationRef} onSelect={setLocationFromSearch} />
      </div>
    </AccordionTab>
  </div>)
}

export { FlightHistoryAddress }