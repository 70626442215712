import { createSlice } from '@reduxjs/toolkit';
import { MAP_CONTROLS, ACCORDION_CONTROLS } from 'utils/globalConstants';

const initialState = {
  currentSidebarModal: null,
  isNoiseAbatementPage: false,
  controls: {
    [MAP_CONTROLS.ALARMS]: false,
    [MAP_CONTROLS.MAP_LAYERS]: false,
    [MAP_CONTROLS.FLIGHTS]: false,
    [MAP_CONTROLS.LIVE_ATC]: false,
    [MAP_CONTROLS.METAF]: false,
    [MAP_CONTROLS.FILTERS]: false,
    [MAP_CONTROLS.CENTER]: false,
    [MAP_CONTROLS.ZOOM_PRESET]: false,
    [MAP_CONTROLS.ZOOM_IN]: false,
    [MAP_CONTROLS.ZOOM_OUT]: false,
    [MAP_CONTROLS.INFO]: false,
  },
  positions: {
    [MAP_CONTROLS.ALARMS]: {
      initialOffsetTop: 70,
      initialOffsetLeft: -250,
      top: 0,
      left: 0,
    },
    [MAP_CONTROLS.MAP_LAYERS]: {
      initialOffsetTop: -10,
      initialOffsetLeft: -320,
      top: 0,
      left: 0,
    },
    [MAP_CONTROLS.FLIGHTS]: {
      initialOffsetTop: -70,
      initialOffsetLeft: -550,
      top: 0,
      left: 0,
    },
    [MAP_CONTROLS.LIVE_ATC]: {
      initialOffsetTop: -70,
      initialOffsetLeft: -550,
      top: 0,
      left: 0,
    },
    [MAP_CONTROLS.FILTERS]: {
      initialOffsetTop: -370,
      initialOffsetLeft: -400,
      top: 0,
      left: 0,
    },
    [MAP_CONTROLS.ZOOM_PRESET]: {
      initialOffsetTop: -100,
      initialOffsetLeft: -320,
      top: 0,
      left: 0,
    },
  },
  accordions: {
    [ACCORDION_CONTROLS.LAYERS_MAP]: true,
    [ACCORDION_CONTROLS.LAYERS_LAYER]: true,
    [ACCORDION_CONTROLS.LAYERS_TARGET]: true,
    [ACCORDION_CONTROLS.LAYERS_WEATHER]: false,
    [ACCORDION_CONTROLS.LAYERS_TAGS]: false,
    [ACCORDION_CONTROLS.LAYERS_SOURCE]: false,
    [ACCORDION_CONTROLS.ATC_LIVE]: true,
    [ACCORDION_CONTROLS.ATC_ARCHIVE]: false,
    [ACCORDION_CONTROLS.FILTERS_AIRCRAFT]: true,
    [ACCORDION_CONTROLS.FILTERS_FLIGHT]: true,
    [ACCORDION_CONTROLS.FILTERS_DEBUG]: false,
  },
};

export const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openNavbarModal(state, action) {
      state.currentSidebarModal = action.payload;
    },
    closeNavbarModal(state) {
      state.currentSidebarModal = null;
    },
    setModalPosition(state, action) {
      const { name, position, useOffset, initialOffsetTop } = action.payload;
      const offsetTop = initialOffsetTop || state.positions[name].initialOffsetTop || 0;
      const offsetLeft = state.positions[name].initialOffsetLeft || 0;

      state.isNoiseAbatementPage = action.payload.isNoiseAbatementPage || false
      state.positions[name].top = useOffset ? position.top + offsetTop : position.top;
      state.positions[name].left = useOffset ? position.left + offsetLeft : position.left;
    },
    toggleControl(state, action) {
      state.controls[action.payload] = !state.controls[action.payload];
    },
    resetControls(state) {
      state.controls = {};
    },
    toggleModalAccordion(state, action) {
      const { payload } = action;
      state.accordions[payload.name] = payload.state;
    },
  },
});

export default modalSlice.reducer;

export const {
  openNavbarModal,
  closeNavbarModal,
  setModalPosition,
  toggleControl,
  resetControls,
  toggleModalAccordion,
} = modalSlice.actions;
