import AccordionTab from 'components/base/AccordionTab';
import Button from 'components/base/Button';
import CustomInput from 'components/base/CustomInput';
import CustomSelect from 'components/base/CustomSelect';
import FormField from 'components/base/FormField';
import { Button as NewButton } from 'components/UI/Button';
import React, { useEffect, useState } from 'react';

const defaultValue = { upperCeiling: 5000, lowerCeiling: 1000, radius: 30, callsign: '', registration: '' }

const FlightHistoryFilter = ({ onChange, onSubmit, activities }) => {
  const [isTabOpened, setTabOpened] = useState(true)
  
  const [filterData, setFilterData] = useState(defaultValue)

  const handleFilterDataChange = (data) => setFilterData({...filterData, ...data}) 

  const onUpperCeilingChange = (event) => {
    handleFilterDataChange({ upperCeiling: +event.target.value })
  }

  const onLowerCeilingChange = (event) => {
    handleFilterDataChange({ lowerCeiling: +event.target.value })
  }

  const onRadiusChange = (event) => {
    handleFilterDataChange({ radius: +event.target.value })
  }

  const onCallsignChange = (event) => {
    const inputValue = event.target.value

    handleFilterDataChange({ callsign: inputValue })
  }

  const onActivityClear = () => {
    handleFilterDataChange({ activity: undefined })
  }

  const onActivityChange = (activity) => {
    handleFilterDataChange({ activity })
  }

  const onRegistrationChange = (event) => {
    const inputValue = event.target.value

    handleFilterDataChange({ registration: inputValue })
  }

  const resetData = () => setFilterData(defaultValue)

  const onTabToggle = (value) => setTabOpened(value)

  useEffect(() => onChange(filterData), [filterData])

  return (    
  <div className='flight-history-tab'>
    <AccordionTab label='Filters' name='filter' opened={isTabOpened} setOpened={onTabToggle}>
      <div className='flight-history-block__header'>
        <NewButton text='Default ' type='outline' size='sm' rounded onClick={resetData} />
      </div>
      <div className='flight-history-data__filter'>
        <div className='flight-history-data__filter-block'>
        <FormField label="Lower Ceiling" name="lowerCeiling" className="noise-input_icon-visible">
            <CustomInput
              name="lowerCeiling"
              type="number"
              value={filterData.lowerCeiling}
              measureUnit="ft"
              max={50000}
              step={100}
              min={0}
              onChange={onLowerCeilingChange}
            />
          </FormField>
        </div>
        <FormField label="Upper Ceiling" name="upperCeiling" className="noise-input_icon-visible">
            <CustomInput
              name="upperCeiling"
              type="number"
              value={filterData.upperCeiling}
              measureUnit="ft"
              max={50000}
              step={100}
              min={0}
              onChange={onUpperCeilingChange}
            />
          </FormField>
      </div>
      <div className='flight-history-data'>
        <FormField label="Select Range/Diameter" name="radius" className="noise-input_icon-visible">
          <CustomInput
            name="radius"
            type="number"
            value={filterData.radius}
            measureUnit="NM"
            max={100}
            min={0}
            onChange={onRadiusChange}
          />
        </FormField>
      </div>
      <div className='flight-history-data'>
        <div className='flight-history-data__filter'>
          <div className='flight-history-data__filter-block'>
            <FormField label="Registration" name="registration" className="noise-input_icon-visible">
              <CustomInput
                name="registration"
                type="text"
                placeholder="N1230F"
                value={filterData.registration}
                onChange={onRegistrationChange}
              />
            </FormField>
          </div>
          <div className='flight-history-data__filter-block'>
            <FormField label="Flight/Callsign" name="callsign" className="noise-input_icon-visible">
              <CustomInput
                name="callsign"
                type="text"
                placeholder="ABCD123"
                value={filterData.callsign}
                onChange={onCallsignChange}
              />
            </FormField>
          </div>
        </div>
      </div>
      <div className='flight-history-data'>
        <div className='flight-history-data__label'>
          Activities available at Airport
        </div>
        <CustomSelect clearable value={filterData.activity} options={activities} onClear={onActivityClear} placeholder='Select Activity' onChange={onActivityChange} />
        <div className='flight-history-data__filter-submit'>
          <Button
            mods={{action: true}}
            className="noise_button"
            type="submit"
            onClick={onSubmit}
          >
            Start Lookup
          </Button>
        </div>
      </div>
    </AccordionTab>
  </div>
  )
}

export { FlightHistoryFilter }