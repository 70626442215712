import * as tokml from 'tokml'
import * as pako from 'pako'
import JSZip from 'jszip';

export class GeometryUtils {

  static geoJsonToPaths(geoJson) {
      if (geoJson.type !== 'FeatureCollection') {
        throw new Error('GeoJSON должен быть FeatureCollection');
      }
    
      return geoJson.features
        .filter((feature) => feature.geometry.type === 'LineString') 
        .map((feature) => ({
          id: feature.id,
          path: feature.geometry.coordinates.map((coord) => {
            const [longitude, latitude, elevation = 0] = coord;

            return [longitude, latitude, elevation < 0 ? 0 : elevation]; 
          }),
        }));
  }

  static generateSegmentsWithElevation(points) {
    const features = [];
  
    for (let i = 0; i < points.length - 1; i++) {
      const start = points[i].geometry.coordinates;
      const end = points[i + 1].geometry.coordinates
      
      const elevation = points[i].properties.alt

      features.push({
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [start[0], start[1], start[2]],
            [end[0], end[1], end[2]]     
          ]
        },
        properties: {
          elevation: elevation 
        }
      });
    }
  
    return {
      type: "FeatureCollection",
      features: features
    };
  }

  static convertGeojsonToKML(geojson, altitudeMode = 'absolute') {
    try {
      // Конвертация GeoJSON в KML
      let kmlData = tokml(geojson);

      kmlData = kmlData.replace(
        /<LineString>/g,
        `<LineString>\n    <altitudeMode>${altitudeMode}</altitudeMode>`
      );

      return kmlData
    } catch (err) {
        alert('Неверный формат GeoJSON: ' + err.message);
    }
  }

  static downloadKML(geojson) {
    const kml = this.convertGeojsonToKML(geojson)
    const kmlLink = document.createElement('a');
      
    const kmlBlob = new Blob([kml], { type: 'application/vnd.google-earth.kml+xml' });

    kmlLink.download = 'output.kml';

    kmlLink.href = URL.createObjectURL(kmlBlob);

    document.body.appendChild(kmlLink);
    
    kmlLink.click()

    kmlLink.remove()
  }

  static downloadKMZ(geojson) {
    const kml = this.convertGeojsonToKML(geojson)

    const zip = new JSZip();
    zip.file('doc.kml', kml);

    zip.generateAsync({ type: 'blob' }).then(function(content) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(content);
        link.download = 'map.kmz';
        link.click();
    });
  }
}