import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import CustomSelect from './index';

const FormikSelect = ({ name, options, afterValueChange, ...props }) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const { value } = field;
        const { touched, errors, setFieldValue, setFieldTouched, setFieldError } = form;
        return (
          <CustomSelect
            id={name}
            options={options}
            error={props.required ? errors[field.name] : touched[field.name] && errors[field.name]}
            name={name}
            value={value}
            onBlur={() => {
              if (!touched[field.name]) setFieldTouched(field.name);
            }}
            onChange={(val) => {
              if (!touched[field.name]) setFieldTouched(field.name);
              if (errors[field.name]) setFieldError(field.name, '');
              setFieldValue(field.name, val);
              if (afterValueChange) {
                afterValueChange(val);
              }
            }}
            {...props}
          />
        );
      }}
    </Field>
  );
};

FormikSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.string,
  onFocus: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  afterValueChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default FormikSelect;
