
const layers = {
  'google.hybrid': {
    id: 'google.hybrid',
    name: 'Hybrid',
    mapType: 'roadmap',
    type: 'google'
  },
  'google.satellite': {
    id: 'google.satellite',
    name: 'Satellite',
    mapType: 'satellite',
    type: 'google'
  },
  'google.terrain': {
    id: 'google.terrain',
    name: 'Terrain',
    mapType: 'terrain',
    type: 'google',
    layerTypes: ["layerRoadmap"]
  },
  'google.roadmap': {
    id: 'google.roadmap',
    name: 'Roadmap',
    url:`https://api.maptiler.com/maps/streets-v2/style.json?key=${process.env.REACT_APP_MAPTILER_KEY}`,
    type: 'vector'
  },
  'Dark': {
    id: 'Dark',
    name: 'Dark',
    url: `https://api.mapbox.com/styles/v1/mapbox/dark-v11?access_token=${process.env.REACT_APP_MAPBOX_KEY}`,
    type: 'vector'
  },
  OSM:{
    id: 'osm',
    name: 'OpenStreetMap',
    tiles: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
    type: 'raster'
  },
  VFR:{
    id: 'VFR',
    name: 'VFR',
    tiles: ['https://tile-proxy.vtt.systems/20240905/tiles/vfrc/{z}/{x}/{y}.jpg'],
    type: 'raster'
  },
  IFRL:{
    id: 'IFRL',
    name: 'IFRL',
    tiles: ['https://tile-proxy.vtt.systems/20240905/tiles/ifrlc/{z}/{y}/{x}.jpg'],
    type: 'raster'
  },
  IFRH:{
    id: 'IFRH',
    name: 'IFRH',
    tiles: ['https://tile-proxy.vtt.systems/20240905/tiles/ehc/{z}/{y}/{x}.jpg'],
    type: 'raster'
  }
}

export { layers }