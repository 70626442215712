import React from 'react';
import './Terrain3D.css'
import { toggle3DTerrain } from 'redux/modules/map'
import { useDispatch, useSelector } from 'react-redux';


const Terrain3DControl = () => {
  const dispatch = useDispatch();

  const { terrain3D } = useSelector(
    (state) => state.map
  );

  const toggleView = () => {
    dispatch(toggle3DTerrain(!terrain3D))
  }

  return (<div className='terrain-3d'>
    <button onClick={toggleView} className={terrain3D ? 'terrain-3d__switch terrain-3d__switch--active' : 'terrain-3d__switch'  }>
      <span></span>
    </button>
    <div className='terrain-3d__label'>3D Terrain</div>
  </div>)
}

export { Terrain3DControl }