import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { setModalPosition } from 'redux/modules/modal';
import { MAP_CONTROLS } from 'utils/globalConstants';
import SideNavigation from 'components/common/SideNavigation';
import PlaneBackground from 'components/base/PlaneBackground';
import FullPageLoader from 'components/base/FullPageLoader';
import Header from 'components/common/Header';
import AlarmsModal from 'components/common/AlarmsModal';
import FlightsModal from 'components/common/MapControls/FlightsModal';
import MapLayersModal from 'components/common/MapControls/MapLayersModal';
import ZoomPresetsModal from 'components/common/MapControls/ZoomPresetsModal';
import FiltersModal from 'components/common/MapControls/FiltersModal';
import LiveATCModal from 'components/common/MapControls/LiveATCModal';
import DNDWrapper from './DNDWrapper';

import './Page.css';

const Page = ({
  children,
  className,
  showHeader = true,
  showPlane = false,
  showSidebar = true,
  showBottomBar = false,
  fullscreen,
  sidebarPanelTitle,
  renderSidebarPanel,
  onSidebarPanelClose,
  sidebarPanelLoading,
}) => {
  const dispatch = useDispatch();
  const { positions } = useSelector((state) => state.modal);
  const { controls } = useSelector((state) => state.modal);

  const moveBox = (id, left, top) => {
    dispatch(
      setModalPosition({
        name: id,
        position: {
          left,
          top,
        },
      })
    );
  };

  return (
    <div className={clsx('page', className, { page_fullscreen: fullscreen, page_header: showHeader })}>
      {showHeader && <Header />}
      <DNDWrapper moveBox={moveBox}>
        <SideNavigation
          showBottomBar={showBottomBar}
          showSidebar={showSidebar}
          sidebarPanelLoading={sidebarPanelLoading}
          sidebarPanelTitle={sidebarPanelTitle}
          renderSidebarPanel={renderSidebarPanel}
          onSidebarPanelClose={onSidebarPanelClose}
        >
          <Suspense fallback={<FullPageLoader />}>{children}</Suspense>
        </SideNavigation>
        {controls[MAP_CONTROLS.ALARMS] && (
          <AlarmsModal left={positions[MAP_CONTROLS.ALARMS].left} top={positions[MAP_CONTROLS.ALARMS].top} />
        )}
        {controls[MAP_CONTROLS.MAP_LAYERS] && (
          <MapLayersModal left={positions[MAP_CONTROLS.MAP_LAYERS].left} top={positions[MAP_CONTROLS.MAP_LAYERS].top} />
        )}
        {controls[MAP_CONTROLS.FLIGHTS] && (
          <FlightsModal left={positions[MAP_CONTROLS.FLIGHTS].left} top={positions[MAP_CONTROLS.FLIGHTS].top} />
        )}
        {controls[MAP_CONTROLS.LIVE_ATC] && (
          <LiveATCModal left={positions[MAP_CONTROLS.LIVE_ATC].left} top={positions[MAP_CONTROLS.LIVE_ATC].top} />
        )}
        {controls[MAP_CONTROLS.FILTERS] && (
          <FiltersModal left={positions[MAP_CONTROLS.FILTERS].left} top={positions[MAP_CONTROLS.FILTERS].top} />
        )}
        {controls[MAP_CONTROLS.ZOOM_PRESET] && (
          <ZoomPresetsModal
            left={positions[MAP_CONTROLS.ZOOM_PRESET].left}
            top={positions[MAP_CONTROLS.ZOOM_PRESET].top}
          />
        )}
      </DNDWrapper>

      {showPlane && <PlaneBackground />}
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  showHeader: PropTypes.bool,
  showPlane: PropTypes.bool,
  showSidebar: PropTypes.bool,
  fullscreen: PropTypes.bool,
  sidebarPanelTitle: PropTypes.node,
  sidebarPanelLoading: PropTypes.bool,
  renderSidebarPanel: PropTypes.func,
  onSidebarPanelClose: PropTypes.func,
};

export default Page;
