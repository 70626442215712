import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Slide, ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import MenuButton from './components/MenuButton';
import NavigationList from './components/NavigationList';
import SideModal from './components/SideModal';
import LoaderHorizontal from 'components/base/LoaderHorizontal';
import { navigationCommonList, navigationGroupViewList, navigationSuperAdminList } from '../../../links';

import 'react-toastify/dist/ReactToastify.css';
import 'styles/notifications.css';
import './SideNavigation.css';

const SideNavigation = ({
  className,
  children,
  showSidebar,
  showBottomBar,
  sidebarPanelTitle,
  renderSidebarPanel,
  onSidebarPanelClose,
  sidebarPanelLoading,
}) => {
  const initialValueOpen = localStorage.getItem('saved_setting_navigation_state')
    ? localStorage.getItem('saved_setting_navigation_state') === 'true'
    : true;
  const [isOpen, setOpen] = useState(initialValueOpen);
  const { currentSidebarModal } = useSelector((state) => state.modal);

  const toggleMenu = () => {
    setOpen((open) => {
      localStorage.setItem('saved_setting_navigation_state', !open);
      return !open;
    });
    // Dispatch resize event to force map recalculate its width
    window.dispatchEvent(new Event('resize'));
  };

  return (
    <div className="navigation__container">
      {showSidebar && (
        <nav
          className={clsx('navigation', className, {
            navigation_open: isOpen,
            navigation_hidden: currentSidebarModal,
            'navigation_sidebar-panel': renderSidebarPanel,
          })}
          aria-labelledby="mainmenulabel"
        >
          <MenuButton className="navigation_menu-button" onClick={toggleMenu} />
          <h2 id="mainmenulabel" className="visuallyhidden">
            Main Menu
          </h2>
          <div
            className={clsx('navigation-scrollable', {
              navigation_open: isOpen,
            })}
          >
            <NavigationList navigationList={navigationCommonList} />
            <NavigationList navigationList={navigationSuperAdminList} delimiter />
            <NavigationList navigationList={navigationGroupViewList} delimiter />
          </div>
        </nav>
      )}
      <div
        className={clsx('navigation__sidebar-panel sidebar-panel', {
          'sidebar-panel_visible': renderSidebarPanel,
        })}
      >
        <div className="sidebar-panel__header">
          <span className="sidebar-panel__title">{sidebarPanelTitle}</span>
          <MenuButton className="sidebar-panel__close" isCloseButton onClick={onSidebarPanelClose} />
        </div>
        <LoaderHorizontal className="sidebar-panel__loader" animate={sidebarPanelLoading} />
        <hr className="sidebar-panel__delimiter" />
        <div className="sidebar-panel__content">{renderSidebarPanel && renderSidebarPanel()}</div>
      </div>
      <div
        className={clsx('navigation__content', {
          'navigation__content_bottom-bar': showBottomBar,
        })}
      >
        {children}
        <ToastContainer
          position="bottom-right"
          transition={Slide}
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={7}
        />
      </div>
    </div>
  );
};

SideNavigation.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  showBottomBar: PropTypes.bool,
  sidebarPanelLoading: PropTypes.bool,
  sidebarPanelTitle: PropTypes.node,
  renderSidebarPanel: PropTypes.func,
  onSidebarPanelClose: PropTypes.func,
};

export { SideModal };
export default SideNavigation;
