const MAP_CONTROLS = {
  FLIGHTS: 'flights',
  MAP_LAYERS: 'layers',
  ZOOM_PRESET: 'zoomPreset',
  FILTERS: 'filters',
  CENTER: 'center',
  ZOOM_IN: 'zoomIn',
  ZOOM_OUT: 'zoomOut',
  INFO: 'info',
  LIVE_ATC: 'atc',
  METAF: 'metaf',
  ALARMS: 'alarms',
  AIRCRAFT_LOOKUP: 'aircraftLookup',
};

export const ACCORDION_CONTROLS = {
  LAYERS_MAP: 'layers_map',
  LAYERS_LAYER: 'layers_layer',
  LAYERS_TARGET: 'layers_target',
  LAYERS_WEATHER: 'layers_weather',
  LAYERS_TAGS: 'layers_tags',
  LAYERS_SOURCE: 'layers_source',
  ATC_LIVE: 'atc_live',
  ATC_ARCHIVE: 'atc_archive',
  FILTERS_AIRCRAFT: 'filters_aircraft',
  FILTERS_FLIGHT: 'filters_flight',
  FILTERS_DEBUG: 'filters_debug',
};

const TABLE_FILTER_TYPES = {
  NUMBER: 'number',
  TEXT: 'text',
  DATE: 'customDate',
  TIMESTAMP: 'timestamp',
};

const TABLE_BUTTON_TYPES = {
  CODE: 'code',
  EDIT: 'edit',
  ADD: 'add',
  ARCHIVE: 'archive',
  DELETE: 'delete',
};

const TABLE_PAGE_SIZE = 100;
const MAX_SECONDS_BETWEEN_FLIGHT_POINTS = 20;

export const TIS_B_CLA_PARAMETERS = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
  TENTH: 10,
  ELEVENTH: 11,
};

export const UAT_CLA_PARAMETERS = {
  FOURTH: 4,
  EIGHTH: 8,
  TWELVE: 12,
};

export { MAP_CONTROLS, TABLE_FILTER_TYPES, TABLE_BUTTON_TYPES, TABLE_PAGE_SIZE, MAX_SECONDS_BETWEEN_FLIGHT_POINTS };
