/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { ReactComponent as Search } from 'components/common/CustomTable/TableFilters/TableGlobalFilter/searchIcon.svg';

import 'components/base/Container/Container.css';
import './LocationSearch.css';

const LocationSearchInput = ({ onSelect, className, innerRef }) => {
  const [address, setAddress] = useState('');

  const handleChange = (newVal) => {
    setAddress(newVal);
    if (!newVal) onSelect(null, null);
  };

  const handleSelect = (newVal) => {
    setAddress(newVal);
    geocodeByAddress(newVal)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        if (onSelect) onSelect(latLng, newVal);
      })
      .catch((error) => console.error('Error', error));
  };

  useEffect(() => {
    if (innerRef) innerRef(setAddress)
  }, []) 

  return (
    <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className={clsx('container-search', className)}>
          <input
            {...getInputProps({
              placeholder: 'Search',
              className: 'input container-search__input',
            })}
            value={address}
          />
          <Search className="container-search__icon" />
          {suggestions.length > 0 && (
            <div className="container_search-dropdown">
              {loading && (
                <div className="container_search-dropdown-item container_search-dropdown-loading">Loading...</div>
              )}
              {suggestions.map((suggestion) => {
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className: clsx('container_search-dropdown-item', {
                        'container_search-dropdown-item-selected': suggestion.description === address,
                      }),
                    })}
                    key={suggestion.description}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

LocationSearchInput.propTypes = {
  className: PropTypes.string,
  onSelect: PropTypes.func,
};

export default LocationSearchInput;
