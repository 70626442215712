import { lazy } from 'react';
import { permissions } from 'utils/userPermissions';
import Dashboard from './pages/Dashboard';
import Operations from './pages/Operations';
import BasedAircrafts from './pages/BasedAircrafts';
import NoiseAbatement from './pages/NoiseAbatement';
import FlightHistoryModal from './pages/FlightHistory';
import WeatherMetaf from './pages/WeatherMetaf';
import Reports from './pages/Reports';
import ReportsBased from './pages/ReportsBased';
import Reports5010 from './pages/Reports5010';
import Statistics from './pages/Statistics';
import Contact from './pages/Contact';
import CustomersSettingsEdit from './pages/CustomersSettingsEdit';

import Users from './pages/Users';
import UsersEdit from './pages/UsersEdit';
import UsersLogins from './pages/UsersLogins';
import Customers from './pages/Customers';
import CustomersEdit from './pages/CustomersEdit';
import Geofences from './pages/Geofences';
import GeofencesEdit from './pages/GeofencesEdit';
import Activities from './pages/Activities';
import ActivitiesEdit from './pages/ActivitiesEdit';
import Aircrafts from './pages/Aircrafts';
import AircraftsEdit from './pages/AircraftsEdit';
import Settings from './pages/Settings';
import Events from './pages/Events';
import ExternalDatasetLADD from './pages/ExternalDatasetLADD';
import ExternalDBFaa from './pages/ExternalDBFaa';
import ExternalBasedAircraft from './pages/ExternalBasedAircraft';
import ExternalDBSync from './pages/ExternalDBSync';
import ReportsCustomerGroups from './pages/ReportsCustomerGroup';
import AuditLog from './pages/AuditLog';
import Screens from './pages/Screens';

const Login = lazy(() => import('./pages/Login'));
const LoginTotp = lazy(() => import('./pages/LoginTotp'));
const DisclaimerPage = lazy(() => import('./pages/DisclaimerPage'));
const TerminalArrAndDep = lazy(() => import('./pages/TerminalArrAndDep'));
const TerminalServices = lazy(() => import('./pages/TerminalServices'));
const ScheduledFlights = lazy(() => import('./pages/ScheduledFlights'));
const OperationsReport = lazy(() => import('./pages/OperationsReport'));
const OperationsReport5010 = lazy(() => import('./pages/OperationsReport5010'));
const BasedAircraftReport = lazy(() => import('./pages/BasedAircraftReport'));
const BasedAircraftsSummary = lazy(() => import('./pages/BasedAircraftReport/BasedAircraftSummary'));

/**
 * @typedef {Object} RouteConfig
 * @property {string} title - The page title, will be set to document.title
 * @property {string} path - Any valid URL path
 * @property {Object} element - React component of the page
 * @property {boolean} exact - When true, will only match if the path matches the
 * @property {RouteConfig[]} children - Array of nested routes
 */
export default [
  {
    title: 'Login',
    path: '/',
    element: Login,
    authRoute: true,
    exact: true,
  },

  {
    title: 'Login Totp',
    path: '/totp',
    element: LoginTotp,
    authRoute: true,
    exact: true,
  },

  {
    title: 'DisclaimerPage',
    path: '/disclaimer',
    element: DisclaimerPage,
    exact: true,
  },

  {
    title: 'Dashboard',
    path: '/dashboard',
    element: Dashboard,
    permission: permissions.PAGE_DEFAULT,
  },

  {
    title: 'Arrivals & Departures',
    path: '/hall',
    element: TerminalArrAndDep,
    permission: permissions.PAGE_AIRPORT_TV_SCREENS,
  },

  {
    title: 'General Aviation Terminal',
    path: '/services',
    element: TerminalServices,
    permission: permissions.PAGE_AIRPORT_TV_SCREENS,
  },

  {
    title: 'Operations',
    path: '/operations',
    element: Operations,
    permission: permissions.PAGE_AIRPORT_DEFAULT,
  },

  {
    title: 'Scheduled Flights',
    path: '/scheduled',
    element: ScheduledFlights,
    permission: permissions.PAGE_SCHEDULED_FLIGHTS,
  },

  {
    path: '/report',
    permission: permissions.PAGE_AIRPORT_DEFAULT,
    children: [
      {
        title: 'Operations Report',
        path: '/report',
        element: OperationsReport,
        exact: true,
      },
      {
        title: '5010 Summary',
        path: '/report/5010',
        element: OperationsReport5010,
      },
      {
        title: 'Based Aircraft Report',
        path: '/report/based',
        element: BasedAircraftReport,
      },
      {
        title: 'Based Aircraft Summary',
        path: '/report/based-summary',
        element: BasedAircraftsSummary,
      },
    ],
  },

  {
    title: 'Aircrafts',
    path: '/based-aircrafts',
    element: BasedAircrafts,
    permission: permissions.PAGE_AIRPORT_DEFAULT,
  },

  {
    title: 'Noise Abatement',
    path: '/noise',
    element: NoiseAbatement,
    permission: permissions.PAGE_NOISE_ABATEMENT,
  },
  {
    title: 'Flight History',
    path: '/flight-history',
    element: FlightHistoryModal,
    permission: permissions.PAGE_NOISE_ABATEMENT,
  },

  {
    title: 'Weather Information',
    path: '/weather',
    children: [
      {
        title: 'METAR & TAF',
        path: '/weather/metaf',
        element: WeatherMetaf,
      },
    ],
    permission: permissions.PAGE_AIRPORT_DEFAULT,
  },

  {
    path: '/reports',
    children: [
      {
        title: 'Generate Operations Reports',
        path: '/reports/operations',
        element: Reports,
      },
      {
        title: 'Generate 5010 Report',
        path: '/reports/5010',
        element: Reports5010,
      },
      {
        title: 'Generate Based Aircraft Reports',
        path: '/reports/based',
        element: ReportsBased,
      },
      {
        title: 'Operations Statistic',
        path: '/reports/statistic',
        element: Statistics,
      },
    ],
    permission: permissions.PAGE_AIRPORT_DEFAULT,
  },

  {
    title: 'Contact',
    path: '/contact',
    element: Contact,
    permission: permissions.PAGE_SUPPORT,
  },

  {
    path: '/users',
    children: [
      {
        title: 'User Edit',
        path: '/users/:userId',
        element: UsersEdit,
      },
      {
        title: 'User Management',
        path: '/users',
        element: Users,
      },
    ],
    permission: permissions.PAGE_SYSTEM_MANAGEMENT,
  },

  {
    path: '/activities',
    children: [
      {
        title: 'Edit activity',
        path: '/activities/:id',
        element: ActivitiesEdit,
      },
      {
        title: 'Activities List',
        path: '/activities',
        element: Activities,
      },
    ],
    permission: permissions.PAGE_SYSTEM_MANAGEMENT,
  },

  {
    path: '/geofences',
    children: [
      {
        title: 'Edit geofence',
        path: '/geofences/:id',
        element: GeofencesEdit,
      },
      {
        title: 'Geofences List',
        path: '/geofences',
        element: Geofences,
      },
    ],
    permission: permissions.PAGE_SYSTEM_MANAGEMENT,
  },

  {
    path: '/customers/',
    children: [
      {
        title: 'Customer Edit',
        path: '/customers/:customerId',
        element: CustomersEdit,
      },
      {
        title: 'Customer Management',
        path: '/customers',
        element: Customers,
      },
    ],
    permission: permissions.PAGE_SYSTEM_MANAGEMENT,
  },

  {
    path: '/aircrafts',
    children: [
      {
        title: 'Aircraft Database',
        path: '/aircrafts/:id',
        element: AircraftsEdit,
      },
      {
        title: 'Aircraft DB List',
        path: '/aircrafts',
        element: Aircrafts,
      },
    ],
    permission: permissions.PAGE_SYSTEM_MANAGEMENT,
  },

  {
    title: 'Audit Log',
    path: '/audit-log',
    element: AuditLog,
    permission: permissions.PAGE_SYSTEM_MANAGEMENT,
  },
  {
    title: 'Backend Status',
    path: '/status',
    element: Events,
    permission: permissions.PAGE_SYSTEM_MANAGEMENT,
  },
  {
    title: 'General System Settings',
    path: '/settings',
    element: Settings,
    permission: permissions.PAGE_SYSTEM_MANAGEMENT,
  },

  {
    title: 'User Settings',
    path: '/user-settings',
    children: [
      {
        title: 'User Settings | Services &  Fees',
        path: '/user-settings/services',
        element: CustomersSettingsEdit,
      },
    ],
    permission: permissions.PAGE_AIRPORT_SETTINGS,
  },

  {
    title: 'FIDS Management',
    path: '/fids',
    children: [
      {
        title: 'FIDS Edit',
        path: '/fids/:screenId',
        element: Screens,
      },
      {
        title: 'FIDS',
        path: '/fids',
        element: Screens,
      },
    ],
    permission: permissions.PAGE_FIDS_MANAGEMENT,
  },

  {
    path: '/external-db',
    children: [
      {
        title: 'Import LADD List',
        path: '/external-db/ladd',
        element: ExternalDatasetLADD,
      },
      {
        title: 'Import FAA Database',
        path: '/external-db/faa',
        element: ExternalDBFaa,
      },
      {
        title: 'Import Based Aircraft',
        path: '/external-db/based',
        element: ExternalBasedAircraft,
      },
      {
        title: 'Syncronize External Databases',
        path: '/external-db/sync',
        element: ExternalDBSync,
      },
    ],
    permission: permissions.PAGE_SYSTEM_MANAGEMENT,
  },

  {
    title: 'Group Access',
    path: '/airport-logins',
    element: UsersLogins,
    permission: permissions.PAGE_AIRPORT_LOGINS,
  },

  {
    title: 'Export',
    path: '/export/group-report',
    element: ReportsCustomerGroups,
    permission: permissions.PAGE_GROUP_REPORT,
  },
];
