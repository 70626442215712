import React from 'react';
import './View3D.css'
import { toggle3DView } from 'redux/modules/map'
import { useDispatch, useSelector } from 'react-redux';


const View3DControl = () => {
  const dispatch = useDispatch();

  const { view3D } = useSelector(
    (state) => state.map
  );

  const toggleView = () => {
    dispatch(toggle3DView(!view3D))
  }

  return (<div className='view-3d'>
    <button onClick={toggleView} className={view3D ? 'view-3d__switch view-3d__switch--active' : 'view-3d__switch'  }>
      <span></span>
    </button>
    <div className='view-3d__label'>2D/3D View</div>
  </div>)
}

export { View3DControl }