/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MapControls from 'components/common/MapControls';
import { PathLayer } from 'deck.gl';
import { MapboxOverlay } from '@deck.gl/mapbox';
import { FlightHistorySettings } from '../../components/common/FlightHistory'
import { GeometryUtils } from 'utils/geometry.utils'

import '../NoiseAbatement/NoiseAbatement.css';
import Page from 'components/common/Page';
import { MapboxMap } from 'components/common/MapboxMap';
import mapboxgl from 'mapbox-gl'
import { LayersUtils } from 'utils/layers.utils';
import api from 'api/api';
import FlightTag from 'pages/NoiseAbatement/FlightTag';
import './FlightHistory.css'
import { SideModal } from 'components/common/SideNavigation';
import { closeNavbarModal } from 'redux/modules/modal';

const MODAL_NAME = 'noiseAbatementNew';


const positionMarker = new mapboxgl.Marker({
  color: '#24c1ff',
})

window.positionMarker = positionMarker

// const addressPopup = new mapboxgl.Popup({ closeOnClick: false })

const PopupComponent = forwardRef((props, ref) => (
  <div ref={ref}>
    <FlightTag {...props} />
  </div>
))

const NoiseAbatement = () => {
  const { view3D } = useSelector((state) => state.map);
  const { airport: localAirport } = useSelector((state) => state.auth);
  const [centerPoint, setCenterPoint] = useState();
  const [centerType, setCenterType] = useState('airport');

  const [aircraftData, setAircraftData] = useState()

  const formData = useRef({})
  const mapState = useRef(null);
  const path3D = useRef(null)
  const popupRef = useRef(null)

  const setDataToLayer = async (data) => {
    const map = mapState.current  

    if (!map) return

    const noiseSource = map.getSource('history-source')

    noiseSource.setData(data)

    if (view3D) setDataTo3DLayer(data)
  }

  const onMapClick = (e) => {

    const [feature] = mapState.current.queryRenderedFeatures(e.point, {
      layers: ['history-source-line']
    })

    if (feature && feature?.id !== undefined ) {
      const hex = feature?.id
      loadCurrentHexData(hex)
      return
    } 

    LayersUtils.addSelectedHistoryLayer(e.map, {
      points: [],
      view3D
    })
    
    if (!mapState.current.centerType) return

    if (mapState.current.centerType === 'airport') return;
    
    const coords = Object.values(e.lngLat)

    positionMarker.setLngLat(coords).addTo(e.map)

    LayersUtils.addRadiusPoint(e.map, {
      center: coords,
      color: '#24c1ff',
      radius: formData.current.radius || 3,
      sourceName: 'marker-radius'
    })

    const points = coords.map(coordinates => +coordinates.toFixed(6))
    setCenterPoint(points)
    setCenterType('')

    mapState.current.centerType = ''
  }

  const handleUpdateHistoryLayer = (updatedGeojson) => {
    const map = mapState.current
    map.moveLayer('history-source-line')

    if (!updatedGeojson?.features) return

    setDataToLayer(updatedGeojson)
  };

  const changePositionToAirport = (map = mapState.current) => {
    const center = [localAirport.lon,localAirport.lat]

    positionMarker.setLngLat(center).addTo(map)

    setCenterPoint(center)
    map.flyTo({
      center,
      zoom: 12,
      duration: 0
    })
  }

  const loadCurrentHexData = async (hex) => {
    try {
      const {to, from} = formData.current
      const { features, registrationInfo } = await api.getHistoryByHex(hex, {dateFrom: from, dateTo: to})

      mapState.current.regAircraft = registrationInfo

      LayersUtils.addSelectedHistoryLayer(mapState.current, {
        points: features,
        view3D 
      })
    } catch (error) {
      console.error(error);
    }
  }

  const onCenterTypeChange = (type) => {
    setCenterType(type)

    mapState.current.centerType = type
    
    if (type === 'airport') changePositionToAirport()

  }

  const createHistoryLayer = (map) => {
    map.addSource('history-source', {
      'type': 'geojson',
      'data': null
    });

    map.addLayer({
      'id': 'history-source-line',
      'type': 'line',
      'source': 'history-source',
      'layout': {
          'line-join': 'round',
          'line-cap': 'round'
      },
      'paint': {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],  
          '#24c1ff',
          // '#1eff00', green
          '#9f40dd'
        ],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          4,
          2
        ] 
      }
    });

    LayersUtils.addHoverLayer(map, 'history-source', 'history-source-line')
  }

  const onMapLayerMove = (e) => {
    const map = mapState.current
    const coordinates = e.lngLat;
    const [feature] = e.features

    map.selectedHistory = feature

    const properties = feature.properties

    const registrationInfo = mapState.current.regAircraft

    setAircraftData({
      speed: properties.spd,
      altitude: properties.alt,
      registration: registrationInfo.registration,
      vSpeed: properties.vrt,
      callsign: properties.callsign,
      squawk: properties.squ,
      type: registrationInfo.type,
      timestamp: properties.received_at,
      src: 'A',
      cla: 0
    })


    map.popup = new mapboxgl.Popup({
      closeButton: false,
      className: 'flight-tag-popup'
    })
      .setLngLat(coordinates)
      .setDOMContent(popupRef.current)
      .addTo(map);
  }

  const onMapLoad = (event) => {
    const map = event.target.style.map
    createHistoryLayer(map)
    mapState.current = map

    if (!map) return 

    if (localAirport) changePositionToAirport(map)


    map.on('mouseenter', 'temporary-history-point-layer', onMapLayerMove);
  
    map.on('mouseleave', 'temporary-history-point-layer', function() {
      map.getCanvas().style.cursor = '';  
      const popup = map.popup
      if (popup) {
          popup.remove();  
      }
    });
  }

  const setLocationFromSearch = (center) => {
    const map = mapState.current

    positionMarker.setLngLat(center).addTo(map)

    LayersUtils.addRadiusPoint(map, {
      center,
      color: '#24c1ff',
      radius: formData.current.radius || 3,
      sourceName: 'marker-radius'
    })
 
    mapState.current.flyTo({
      center: center,
      zoom: 16,
      duration: 0
    })

    setCenterPoint(center)
  };

  const setDataTo3DLayer = (data) => {
    const map = mapState.current
    const lines = GeometryUtils.geoJsonToPaths(data);

    const layers = [
      new PathLayer({
        id: 'path-layer',
        data: lines,
        getPath: d => d.path,
        getColor: mapState.current.colorFor3DView || [159, 64, 221],
        widthUnits: 'pixels',
        opacity: 1,
        getWidth: 1.2,
        rounded: true, 
        widthScale: 1,
        pickable: true,
        autoHighlight: true,
        highlightColor: [31, 255, 0],
        onClick: ({object}) => loadCurrentHexData(object.id)
      }),
    ];

    if (path3D.current) {
      path3D.current.setProps({layers})
      return
    }

    const deckOverlay = new MapboxOverlay({ layers });
    map.addControl(deckOverlay);
    path3D.current = deckOverlay
  }

  const toggle3DLayer = async () => {
    const map = mapState.current

    if (!map) return;

    const noiseSource = map.getSource('history-source');

    if (!noiseSource) return;

    const visibility = view3D ? 'none' : 'visible'

    if (view3D) {
      const data = await noiseSource._data

      if (!data) return

      setDataTo3DLayer(data)

      map.setLayoutProperty('history-source-line', 'visibility', visibility)

      return
    }

    path3D.current?.setProps({
        layers: []
    })

    map.setLayoutProperty('history-source-line', 'visibility', visibility)
    map.moveLayer('history-source-line')
  }

  const onDataChange = (historySettings) => {
    formData.current = historySettings

    if (mapState.current) LayersUtils.addRadiusPoint(mapState.current, {
      center: centerPoint,
      color: '#24c1ff',
      radius: formData.current.radius || 30,
      sourceName: 'marker-radius'
    })
  }

  const onLayerChange = (layer) => {
    if (!mapState.current) return

    const layers = mapState.current.getStyle().layers

    const isLayerDarkTheme = ['google.satellite', 'Dark'].includes(layer.id)

    if (path3D) {
      const historySource = mapState.current.getStyle().sources['history-source']

      mapState.current.colorFor3DView = isLayerDarkTheme ? [255,255,255] : [159, 64, 221]

      setDataTo3DLayer(historySource.data)
    }

    layers.forEach(({id, source}) => {
      if (['marker-radius-marker', 'history-source', 'temporary-history-point', 'temporary-history-line'].includes(source)) {

        if (source === 'history-source') {
          mapState.current.setPaintProperty(id, 'line-color', 
            [
              'case',
              ['boolean', ['feature-state', 'hover'], false],  
              '#24c1ff',
              // '#1eff00', green
              isLayerDarkTheme ? 'white' : '#9f40dd'
            ]
          )
        } 

        if (['temporary-history-line', 'temporary-history-point'].includes(source)) mapState.current.moveLayer(id) 

        mapState.current.moveLayer(id)
      }
    })
  }
  
  useEffect(() => {
    setDataToLayer({type: 'FeatureCollection', features: []})
  }, [centerPoint])

  useEffect(() => {
    toggle3DLayer()

    LayersUtils.addSelectedHistoryLayer(mapState.current, {
      points: null,
      view3D 
    })
  }, [view3D])

  const dispatch = useDispatch();
  
  return (
    <Page fullscreen>
      <div>
      {aircraftData && <PopupComponent ref={popupRef} {...aircraftData}/>}
      <SideModal
        isOpen
        onRequestClose={() => dispatch(closeNavbarModal())}
        className="noise"
      >
        <FlightHistorySettings centerPoint={centerPoint} map={mapState.current} onCenterTypeChange={onCenterTypeChange} centerType={centerType}  onHistoryResponse={handleUpdateHistoryLayer} setLocationFromSearch={setLocationFromSearch} onDataChange={onDataChange} />
      </SideModal>
        <MapControls isNoiseAbatementPage>
          {() => (
            <MapboxMap onMapClick={onMapClick} onBaseLayerChange={onLayerChange} onMapLoad={onMapLoad} />
          )}
        </MapControls>
      </div>
    </Page>
  );
};

export default NoiseAbatement;
